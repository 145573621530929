import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { bbox } from "@turf/turf";
import { stringify } from 'csv-stringify/browser/esm/sync';
import { DownZipFile } from "downzip";
import { Feature, FeatureCollection, Geometry, MultiPolygon, Polygon } from "geojson";
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import slufigy from "slugify";
import { Project } from 'src/app/core/models/project.model';
import { Attachment, Species, Survey, SurveyLog } from 'src/app/core/models/survey.model';
import { DownloadService } from "src/app/core/services/download.service";
import { UtilsService } from "src/app/core/services/utils.service";
import { environment } from "src/environments/environment";
const { GeoPackageAPI, GeometryColumns, FeatureColumn, GeometryType, setSqljsWasmLocateFile, GeoPackageDataType } = (window as any).GeoPackage;

const XlsxPopulate = (window as any).XlsxPopulate;

export type SurveyFeatureProperties = {
  [P in keyof Omit<Survey, 'images' | 'type' | 'userId' | 'species' | 'state' | 'logs'>]: string | undefined | number;
} & {
  id: number | string;
  l2?: string;
}

export const MAP_DEFAULT_BOUNDS: mapboxgl.LngLatBoundsLike = [
  2.0153808593734936, 56.6877748258257,
  -7.0153808593762506, 47.45206245445874
];
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private downloadService: DownloadService, private utilsService: UtilsService, private httpClient: HttpClient) { }

  private readonly _project = new BehaviorSubject<Project | null>(null);
  project$ = this._project.asObservable();
  surveys$ = this.project$.pipe(map((project) => project?.surveys ?? []));
  presurveys$ = this.project$.pipe(map((project) => project?.presurveys ?? []));
  users$ = this.project$.pipe(map(project => project?.users ?? []));

  boundary$: Observable<Feature<Polygon | MultiPolygon> | null> = this.project$.pipe(
    map(project => {
      if (project?.geometry) {
        return {
          type: 'Feature',
          geometry: project.geometry,
          properties: {}
        }
      }
      return null;
    })
  );

  boundaryBoundingBox$ = this.boundary$.pipe(
    map(boundary => {
      return boundary ? bbox(boundary) as mapboxgl.LngLatBoundsLike : null;
    })
  );

  logs$ = this.surveys$.pipe(
    map((surveys) => {
      const logs: SurveyLog[] = [];
      for (const survey of surveys) {
        for (const log of survey.logs) {
          logs.push(log);
        }
      }
      logs.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      return logs;
    })
  );

  getProject(): Project | null {
    return this._project.value;
  }

  getSlug(): string {
    return slufigy(this.getProject()?.name ?? 'ukhab', {
      lower: true,
      trim: true,
      strict: true
    });
  }

  setProject(project: Project) {
    this._project.next(project);
  }

  reset() {
    // this._selectedSurveyIds.next([]);
    this._project.next(null);
    // this._survey.next(null);
    // this._geometryEdits.next({});
    // this._habitatFilter.next({ ...emptyHabitatFilter });
    // this._geometryFilter.next({ ...emptyGeometryFilter });
    // this._hoveredSurveyId.next([null, null]);
    // this._mapEditing.next(false);
    // this._showOverlaps.next(false);
  }

  createDownload() {

  }

  exportImages(surveys: Survey[]): Promise<string> {
    const project = this.getProject();
    const files = this.imageFiles(surveys);

    if (!files.length) return '' as any;
    // Initialize download
    const downloadId = `ukhab-${project?.id || 0}`;
    const zipFileName = `ukhab-project-${project?.id || 0}-media`;
    return this.downloadService.createDownload(downloadId, zipFileName, files);
  }

  async exportMetric(surveys: Survey[]): Promise<Blob> {
    const data = await (await fetch('/assets/metric.xlsx')).arrayBuffer();
    const workbook = await XlsxPopulate.fromDataAsync(data);

    // Populate the A-1 On-Site sheet
    const sheet = workbook.sheet('A-1 On-Site Habitat Baseline');

    let row = 11;
    const columns = {
      broadHabitat: 'E',
      habitatType: 'F',
      area: 'H',
      irreplaceable: 'G',
      condition: 'K',
      strategicSignificance: 'M',
      gisReferenceNumber: 'AB'
    };

    // Find all the area surveys
    const areaSurveys = surveys.filter(survey => survey.hedge !== true && survey.geometry?.type === 'Polygon' || survey?.geometry?.type === 'MultiPolygon') ?? [];

    for (const survey of areaSurveys) {
      // Broad Habitat
      sheet.cell(`${columns.broadHabitat}${row}`).value(survey.metBrdHab);
      sheet.cell(`${columns.habitatType}${row}`).value(survey.metHab);
      sheet.cell(`${columns.area}${row}`).value(survey.area ?? survey.length ?? 0);
      sheet.cell(`${columns.condition}${row}`).value(survey.conResult);
      sheet.cell(`${columns.strategicSignificance}${row}`).value('Area/compensation not in local strategy/ no local strategy');
      sheet.cell(`${columns.gisReferenceNumber}${row}`).value(survey.id);

      const irreplaceable = survey.metHabIrr === true ? 'Yes' : (survey.metHabIrr === false ? 'No' : '');
      sheet.cell(`${columns.irreplaceable}${row}`).value(irreplaceable);
      row++;
    }

    // Populate the B-1 On-Site Hedge Baseline
    const hedgeSheet = workbook.sheet('B-1 On-Site Hedge Baseline');
    let hedgeRow = 10;
    let hedgeNumber = 1;
    const hedgeColumns = {
      hedgeNumber: 'C',
      habitatType: 'D',
      length: 'E',
      condition: 'H',
      strategicSignificance: 'J',
      gisReferenceNumber: 'X'
    };
    const hedgeSurveys = surveys.filter(survey => survey.hedge === true) ?? [];
    for (const hedge of hedgeSurveys) {
      hedgeSheet.cell(`${hedgeColumns.hedgeNumber}${hedgeRow}`).value(hedgeNumber++);
      hedgeSheet.cell(`${hedgeColumns.habitatType}${hedgeRow}`).value(hedge.metHab);
      hedgeSheet.cell(`${hedgeColumns.length}${hedgeRow}`).value(hedge.length);
      hedgeSheet.cell(`${hedgeColumns.condition}${hedgeRow}`).value(hedge.conResult);
      hedgeSheet.cell(`${hedgeColumns.strategicSignificance}${hedgeRow}`).value('Area/compensation not in local strategy/ no local strategy');
      hedgeSheet.cell(`${hedgeColumns.gisReferenceNumber}${hedgeRow}`).value(hedge.id);
      hedgeRow++;
    }

    return await workbook.outputAsync() as Promise<Blob>;
  }

  async exportShapefile(surveys: Survey[]): Promise<Blob> {
    const fc = this.surveysToFeatureCollection(surveys);
    const response = await lastValueFrom(this.httpClient.post(`${environment.apiUrl}/shapefile`, fc, {
      responseType: 'arraybuffer'
    }));
    const blob = new Blob([response], { type: 'application/zip' });
    return blob;
  };

  private surveysToFeatureCollection(surveys: Survey[]): FeatureCollection<Geometry, SurveyFeatureProperties> {

    return {
      type: 'FeatureCollection',
      features: surveys.filter(s => !!s.geometry).map(survey => ({
        type: 'Feature',
        geometry: survey.geometry,
        id: survey.id,
        properties: {
          id: survey.id ?? '',
          area: survey.area ?? 0,
          createdAt: survey.createdAt ?? '',
          updatedAt: survey.updatedAt ?? '',
          length: survey.length ?? '',
          ukHabPCode: survey.ukHabPCode ?? '',
          ukHabPName: survey.ukHabPName ?? '',
          ukHabCCode: survey.ukHabCCode ?? '',
          conNotes: survey.conNotes ?? '',
          conCrit: survey.conCrit ?? '',
          conScore: survey.conScore ?? '',
          conResult: survey.conResult ?? '',
          metBUnits: survey.metBUnits ?? '',
          metBrdHab: survey.metBrdHab ?? '',
          metHUnits: survey.metHUnits ?? '',
          metHab: survey.metHab ?? '',
          metHabType: survey.metHabType ?? '',
          metMsg: survey.metMsg ?? '',
          spsTotal: survey.spsTotal ?? '',
          spsPerQ: survey.spsPerQ ?? '',
          nativeWSps: survey.nativeWSps ?? '',
          qRecorded: survey.qRecorded ?? '',
          ukHabS1Cod: survey.ukHabS1Cod ?? '',
          ukHabS1Nam: survey.ukHabS1Nam ?? '',
          ukHabS2Cod: survey.ukHabS2Cod ?? '',
          ukHabS2Nam: survey.ukHabS2Nam ?? '',
          ukHabS3Cod: survey.ukHabS3Cod ?? '',
          ukHabS3Nam: survey.ukHabS3Nam ?? '',
          ukHabS4Cod: survey.ukHabS4Cod ?? '',
          ukHabS4Nam: survey.ukHabS4Nam ?? '',
          ukHabS5Cod: survey.ukHabS5Cod ?? '',
          ukHabS5Nam: survey.ukHabS5Nam ?? '',
          ukHabS6Cod: survey.ukHabS6Cod ?? '',
          ukHabS6Nam: survey.ukHabS6Nam ?? '',
          l2: survey.ukHabPCode?.slice(0, 1),
          featName: survey.featName ?? ''
        }
      }))
    };
  }

  async exportGeopackage(surveys: Survey[]): Promise<Blob> {
    const tableName = 'features';
    setSqljsWasmLocateFile((file: any) => file);

    const geometryColumns = new GeometryColumns();
    geometryColumns.table_name = tableName;
    geometryColumns.column_name = 'geometry';
    geometryColumns.geometry_type_name = GeometryType.nameFromType(GeometryType.GEOMETRY);
    geometryColumns.z = 0;
    geometryColumns.m = 0;

    const features = this.surveysToFeatureCollection(surveys);

    let columnId = 0;
    const columns = [
      FeatureColumn.createPrimaryKeyColumn(columnId++, 'id', true),
      FeatureColumn.createGeometryColumn(columnId++, 'geometry', GeometryType.GEOMETRY, false, null),
    ];

    for (const k in features.features[0].properties) {
      if (features.features[0].properties.hasOwnProperty(k) && k !== 'id') {
        columns.push(FeatureColumn.createColumn(columnId++, k, GeoPackageDataType.fromName('TEXT'), false, null));
      }
    }

    const filename = '/assets/empty.gpkg';
    const empty = await (await fetch(filename)).arrayBuffer();
    const uInt8Array = new Uint8Array(empty);
    const pkg = await GeoPackageAPI.open(uInt8Array);

    pkg.createFeatureTable(tableName, geometryColumns, columns);
    const featureDao = pkg.getFeatureDao('features');
    const srs = featureDao.srs;

    for (const feature of features.features) {
      for (const [k, v] of Object.entries(feature.properties)) {
        if (typeof v === 'undefined') {
          console.warn('Undefined', k, v, feature);
        }
      }
      pkg.addGeoJSONFeatureToGeoPackageWithFeatureDaoAndSrs(feature, featureDao, srs, true);
    }

    const buffer = await pkg.export();
    return new Blob([buffer], { type: 'application/geopackage+sqlite3' });
  }

  exportCSVFiles(data: Survey[]) {

    const species: (Omit<Species, 'daforScore' | 'dominScore'> & { surveyId: number; daforScore: string | undefined; dominScore: string | undefined; })[] = [];
    const images: (Attachment & { surveyId: number })[] = [];
    const surveys: (Omit<Survey, 'images' | 'species' | 'logs' | 'userId'>)[] = [];

    for (const survey of data) {
      const { images: surveyImages, species: surveySpecies, logs, userId, ...surveyData } = survey;
      surveyData.featName = surveyData.featName ?? '';

      surveys.push(surveyData);
      if (surveyImages) {
        for (const image of surveyImages) {
          images.push({
            ...image,
            surveyId: survey.id
          });
        }
      }
      if (surveySpecies) {
        for (const speciesItem of surveySpecies) {
          species.push({
            ...speciesItem,
            surveyId: survey.id,
            daforScore: speciesItem.daforScore && this.utilsService.getDafor(speciesItem.daforScore),
            dominScore: speciesItem.dominScore && this.utilsService.getDomin(speciesItem.dominScore),
          });
        }
      }
    }
    return [species, images, surveys].map(csv => stringify(csv, {
      header: true,
      cast: {
        boolean: (value: boolean) => {
          return value ? '1' : '0'
        }
      }
    }));
  }

  exportCSVBlobs(data: Survey[]): Blob[] {
    return this.exportCSVFiles(data).map(csv => new Blob([csv], { type: 'text/csv' }));
  }

  exportCSVDownzipFiles(data: Survey[]): Promise<DownZipFile[]> {
    const [species, images, surveys] = this.exportCSVBlobs(data);
    return Promise.all([
      this.downloadService.blobToDownZipFile(species, 'species.csv'),
      this.downloadService.blobToDownZipFile(images, 'images.csv'),
      this.downloadService.blobToDownZipFile(surveys, 'surveys.csv')
    ]);
  }

  async exportCSV(data: Survey[]): Promise<string> {
    const downloadId = `ukhab-${this._project.value?.id}`;
    const zipFileName = `ukhab-project-${this._project.value?.id}-export`;
    const files = await this.exportCSVDownzipFiles(data);
    return await this.downloadService.createDownload(downloadId, zipFileName, files);
  }


  async exportAll(surveys: Survey[]) {
    const files: DownZipFile[] = [];
    const slug = this.getSlug();

    // Images
    const imageFiles = this.imageFiles(surveys);
    files.push(...imageFiles);

    // Metric
    let metricPromise: Promise<number>;
    if (this._project.value?.ukHabVersion === 'ukhab4.0') {
      metricPromise = this.exportMetric(surveys)
        .then(metric => this.downloadService.blobToDownZipFile(metric, `${slug}-metric4.0-calculation-tool.xlsx`))
        .then(metricFile => files.push(metricFile));
    } else {
      metricPromise = Promise.resolve(0);
    }

    // Shapefile
    const shapefilePromise = this.exportShapefile(surveys)
      .then(shapefile => this.downloadService.blobToDownZipFile(shapefile, `${slug}.zip`))
      .then(shapefileFile => files.push(shapefileFile));

    // Geopackage
    const geopackagePromise = this.exportGeopackage(surveys)
      .then(geopackage => this.downloadService.blobToDownZipFile(geopackage, `${slug}.gpkg`))
      .then(geopackageFile => files.push(geopackageFile));

    // CSV
    const csvPromise = await this.exportCSVDownzipFiles(surveys).then(csvFiles => files.push(...csvFiles))

    await Promise.all([metricPromise, shapefilePromise, geopackagePromise, csvPromise]);

    const downloadId = `ukhab-${slug}-${new Date().valueOf().toString()}`;
    const zipFileName = `ukhab-project-${slug}-export`;

    return await this.downloadService.createDownload(downloadId, zipFileName, files);
  }

  private imageFiles(surveys: Survey[]): DownZipFile[] {
    const files: DownZipFile[] = [];

    for (const survey of surveys) {
      if (survey.images) {
        for (const attachment of survey.images) {
          const url = new URL(attachment.url);
          const path = url.pathname.split('/');
          const filename = path[path.length - 1];
          const ext = filename.match(/\.[0-9a-z]+$/i);

          files.push({
            name: `${survey.id}/${filename}${ext ? '' : '.jpg'}`,
            size: attachment.size,
            downloadUrl: attachment.url
          });
        }
      }
    }
    return files;
  }

}
