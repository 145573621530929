import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { User } from "../models/user.model";
import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import * as Sentry from "@sentry/angular-ivy";
@Injectable({ providedIn: 'root' })
export class UserService {

  private currentUserSubject = new BehaviorSubject<User | null>(null);
  public currentUser$ = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public isAuthenticated$ = this.currentUser$.pipe(map((user) => !!user));
  public userImage$ = this.currentUser$.pipe(map((user) => user?.imageUrl ?? '/assets/icons/user.svg'));

  // public organisationsSubject = new BehaviorSubject<UserOrganisation[]>([]);
  // public organisations$ = this.organisationsSubject.asObservable();

  // public adminOrganisations$ = this.organisations$.pipe(
  //   map((organisations) => organisations.filter((a) => a.role === 'admin' || a.role === 'owner'))
  // );

  // public currentOrganisationSubject = new BehaviorSubject<UserOrganisation | null>(null);
  // public currentOrganisation$ = this.currentOrganisationSubject.asObservable();

  constructor(
    private readonly apiService: ApiService,
    private readonly jwtService: JwtService,
    private readonly router: Router
  ) { }

  login(email: string, password: string): Observable<User> {
    const query = `mutation UKHabPortalLogin($email: String!, $password: String!) {
      login(input: { email: $email, password: $password } ) {
        user{
          id
          name: displayName
          image: imageUrl
        }
        token
      }
    }`;
    return this.apiService
      .graphql<{ login: { token: string; user: User } }>(query, { email, password })
      .pipe(
        tap(result => this.setAuth(result.login.token, result.login.user)),
        map(result => result?.login?.user)
      );
  }

  // loadOrganisations() {
  //   const query = `query UKHabPortalQueryAdminOrganisations {
  //     viewer{
  //       o: organisationMemberships{
  //         role
  //         id: organisationId
  //         organisation{
  //           name
  //         }
  //       }
  //     }
  //   }`;

  //   return this.apiService.graphql<{ viewer: { o: { role: string; id: number; organisation: { name: string; } }[] } }>(query).pipe(
  //     tap(result => {
  //       const organisations: UserOrganisation[] = result.viewer.o.map(a => ({
  //         id: a.id,
  //         name: a.organisation.name,
  //         role: a.role
  //       }));
  //       this.organisationsSubject.next(organisations);
  //     })
  //   );
  // }

  logout(): void {
    this.purgeAuth();
    this.router.navigateByUrl('/login');
  }

  setAuth(token: string, user: User): void {
    this.jwtService.setToken(token);
    Sentry.setUser({
      email: user.email,
      id: user.id.toString(),
      username: user.username
    });
    this.currentUserSubject.next(user);
  }

  purgeAuth(): void {
    this.jwtService.destroyToken();
    Sentry.setUser(null);
    this.currentUserSubject.next(null);
  }

}
