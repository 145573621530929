import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { JwtService } from "../services/jwt.service";

interface GraphQLResponse<T> {
  data: T;
  errors: any;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private readonly JwtService: JwtService
  ) { }

  graphql<T = any>(query: string, variables: Record<string, any> = {}) {
    const token = this.JwtService.getToken();
    return this.http.post<GraphQLResponse<T>>('https://api.coreo.io/graphql', {
      query,
      variables
    }, {
      headers: {
        'Authorization': `JWT ${token ? token : ''}`,
        'Content-Type': 'application/json'
      }
    }).pipe(map(response => {
      if (response.errors) throw new Error(response.errors[0].message);
      return response.data as T;
    }));
  }
}
