import { Component, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { UserService } from '../services/user.service';
import { AsyncPipe, NgIf, NgFor, Location, NgClass } from '@angular/common';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { combineLatest, filter, map, startWith } from 'rxjs';
import { ProjectService } from 'src/app/features/project/project.service';
import { ProjectsService } from '../services/projects.service';
import { OrganisationsService } from '../services/organisations.service';
import { Organisation } from '../models/organisation.model';
import { SvgIconComponent } from 'angular-svg-icon';
@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    NgIf,
    NgClass,
    RouterLink,
    NgFor,
    SvgIconComponent
  ]
})
export class HeaderComponent {

  image$ = this.userService.userImage$;
  loggedIn$ = this.userService.isAuthenticated$;
  org$ = this.organisationsService.currentOrganisation$;
  orgs$ = this.organisationsService.organisations$;
  orgsCount$ = this.orgs$.pipe(
    map(orgs => orgs.length)
  );

  url$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map((e: NavigationEnd) => e.urlAfterRedirects),
  );

  switchOrgs$ = combineLatest([this.url$, this.orgsCount$]).pipe(
    map(([url, count]) => count > 0 && url === '/')
  );

  project$ = this.projectService.project$;

  projects$ = combineLatest([
    this.projectsService.query(),
    this.organisationsService.currentOrganisationId$
  ]).pipe(map(([projects, orgId]) => projects.filter(project => project.organisationId === orgId)))

  constructor(
    private userService: UserService,
    private router: Router,
    private organisationsService: OrganisationsService,
    private projectService: ProjectService,
    private projectsService: ProjectsService,
  ) { }

  switchOrganisation(org: Organisation) {
    this.organisationsService.setOrganisation(org.id);
  }

  logout() {
    this.userService.logout();
    console.log('Logging out');
  }

}
