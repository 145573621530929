import { inject } from "@angular/core";
import { ResolveFn, Router } from "@angular/router";
import { Project } from "./core/models/project.model";
import { ProjectsService } from "./core/services/projects.service";
import { mergeMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { ProjectService } from "./features/project/project.service";
import { OrganisationsService } from "./core/services/organisations.service";

export const projectResolver: ResolveFn<Project> = (route) => {
  const projectsService = inject(ProjectsService);
  const projectService = inject(ProjectService);
  const organisationService = inject(OrganisationsService);

  const router = inject(Router);
  return projectsService.get(parseInt(route.paramMap.get("id")!)).pipe(mergeMap(project => {
    if (project) {
      projectService.setProject(project);
      organisationService.setOrganisation(project.organisationId);
      return of(project);
    }
    router.navigate(['/']);
    return EMPTY;
  }));
};
