import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

const DEMO_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEyMywiZW1haWwiOiJkZW1vQHVraGFiLm9yZyIsImRpc3BsYXlOYW1lIjoiVUtIYWIgRGVtbyBVc2VyIiwidXNlcm5hbWUiOiJ1a2hhYmRlbW8iLCJpbWFnZVVybCI6bnVsbCwiaWF0IjoxNzA2MTEyMzk5fQ.bdM3aqlxPVx3V9RXOWifJFev-pDX233bI2AU78ga9Cg';
@Injectable({ providedIn: 'root' })
export class DemoInterceptor implements HttpInterceptor {
  constructor() { }

  private preSurveyId = 1;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!environment.demo) {
      return next.handle(req);
    }

    // Handle Coreo API
    if (req.url.startsWith('https://api.coreo.io')) {
      return of(new HttpResponse({
        status: 200, body: {
          data: {
            login: {
              token: DEMO_TOKEN,
              user: {
                id: 123,
                name: 'Demo User',
                image: null
              }
            }
          }
        }
      }))
    }

    let path: string;

    if (req.url.startsWith('http')) {
      const url = new URL(req.url);
      path = url.pathname;
    } else {
      path = req.url.split('?')[0];
    }
    console.log('Demo mode go!', path);

    if (!path.startsWith('/.netlify/functions')) {
      return next.handle(req);
    }


    switch (true) {
      case path.endsWith('/roles'): {
        req = req.clone({
          url: './assets/demo/roles.json'
        });
        break;
      }
      case path.endsWith('/projects'): {
        req = req.clone({
          url: './assets/demo/projects.json'
        });
        break;
      }
      case path.endsWith('project'): {
        req = req.clone({
          url: './assets/demo/project.json'
        });
        break;
      }
      case path.endsWith('/featurename'):
      case path.endsWith('/geometry'): {
        return of(new HttpResponse({ status: 200 }));
      }
      case path.endsWith('/pre-survey') && req.method === 'DELETE':
      case path.endsWith('/pre-survey') && req.method === 'PUT': {
        return of(new HttpResponse({ status: 200 }));
      }
      case path.endsWith('/pre-survey') && req.method === 'POST': {
        return of(new HttpResponse({
          status: 200, body: {
            id: this.preSurveyId++
          }
        }));

      }

    }
    return next.handle(req);
  }
}
