import { Injectable } from "@angular/core";
import { ProjectBaseService } from "../project-base.service";
import { PreSurvey } from "src/app/core/models/presurvey.model";
import { ProjectService } from "../project.service";
import { HttpClient } from "@angular/common/http";
import { Geometry, Feature } from "geojson";
import { environment } from "src/environments/environment";
import area from "@turf/area";
import { forkJoin } from "rxjs";

@Injectable()
export class ProjectPreSurveyService extends ProjectBaseService<PreSurvey, PreSurvey & { ukHabPCode: string }>{

  boundary$ = this.projectService.boundary$;
  boundaryBoundingBox$ = this.projectService.boundaryBoundingBox$;

  override updateGeometries(updates: Record<number, Geometry>): void {

    const project = this.projectService.getProject();

    for (const [id, geometry] of Object.entries(updates)) {
      const idx = project!.presurveys.findIndex(s => s.id === +id);
      if (idx === -1) {
        return;
      }
      project!.presurveys[idx].geometry = geometry;
      project!.presurveys[idx].area = area({
        type: 'Feature',
        geometry,
        properties: {}
      }) / 10000;
    }
    this.projectService.setProject(project!)

    const obs = [];
    for (const [id, geometry] of Object.entries(updates)) {
      obs.push(this.httpClient.put(`${environment.apiUrl}/pre-survey`, {
        id,
        geometry
      }));
    }

    forkJoin(obs).subscribe(() => {
      console.log('Updated');
    });
  }

  override dataToFeature(data: PreSurvey): Feature<Geometry, PreSurvey & { ukHabPCode: string }> {
    return {
      type: 'Feature',
      id: data.id,
      geometry: data.geometry,
      properties: {
        ...data,
        ukHabPCode: data.UKHab_L2 ?? ''
      }
    };
  }

  override createGeometry(geometry: Geometry) {
    const input = {
      geometry,
      properties: {
        surveyId: this.projectService.getProject()?.preSurveyFormId
      }
    };

    this.httpClient.post<{ id: number }>(`${environment.apiUrl}/pre-survey`, input).subscribe((res) => {
      const project = this.projectService.getProject();
      project!.presurveys.push({
        id: res.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        geometry,
        UKHab_L2: ''
      });
      this.projectService.setProject(project!);
    });
  }

  constructor(private projectService: ProjectService, private httpClient: HttpClient) {
    super(projectService.presurveys$);
  }

  updateSurveyGeometry(id: number, geometry: Geometry) {
    const project = this.projectService.getProject();
    if (!project) {
      return;
    }
    const _changes = this._geometryEdits.value;
    const idx = project.presurveys.findIndex(s => s.id === id);
    if (idx === -1) {
      _changes[0] = [geometry, geometry];
    } else if (!_changes[id]) {
      _changes[id] = [project.presurveys[idx].geometry, geometry];
    } else {
      _changes[id][1] = geometry;
    }
    this._geometryEdits.next(_changes);
  }

  deletePreSurvey(id: number) {
    const project = this.projectService.getProject();

    this.httpClient.delete(`${environment.apiUrl}/pre-survey?projectId=${project!.id}&id=${id}`).subscribe(() => {
      const project = this.projectService.getProject();
      const idx = project!.presurveys.findIndex(s => s.id === id);
      if (idx === -1) {
        return;
      }
      project!.presurveys.splice(idx, 1);
      this.projectService.setProject(project!);
    });
  }

  updateL2(id: number, l2: string) {
    const project = this.projectService.getProject();
    const path = project!.presurveyL2AttributePath;

    this.httpClient.put(`${environment.apiUrl}/pre-survey`, {
      id,
      data: {
        [path!]: l2
      }
    }).subscribe(() => {
      if (!project) {
        return;
      }
      const idx = project.presurveys.findIndex(s => s.id === id);
      if (idx === -1) {
        return;
      }
      project.presurveys[idx].UKHab_L2 = l2;
      this.projectService.setProject(project!);
    });
  }
}
