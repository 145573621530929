import { AllGeoJSON, bbox, clone, explode } from "@turf/turf";
import { FeatureCollection, Geometry } from "geojson";
import { BehaviorSubject, Observable, combineLatest, map } from "rxjs";

interface ProjectBaseData {
  id: number;
  geometry?: Geometry
}
export abstract class ProjectBaseService<T extends ProjectBaseData, F extends any> {

  protected readonly _selectedIds = new BehaviorSubject<number[]>([]);
  selectedIds$ = this._selectedIds.asObservable();

  protected readonly _selectedSource = new BehaviorSubject<'map' | 'table' | null>(null);
  selectedSource$ = this._selectedSource.asObservable();

  protected readonly _hoveredId = new BehaviorSubject<[number | string | null, number | string | null]>([null, null]);
  hoveredId$ = this._hoveredId.asObservable();

  protected readonly _geometryEdits = new BehaviorSubject<Record<number, [Geometry, Geometry]>>({});
  geometryEdits$ = this._geometryEdits.asObservable();
  hasEdits$ = this.geometryEdits$.pipe(map(edits => Object.keys(edits).length > 0));

  protected readonly _mapEditing = new BehaviorSubject<boolean>(false);
  mapEditing$ = this._mapEditing.asObservable();

  public selected$: Observable<T[]>;
  public selectedCount$: Observable<number>;
  public empty$: Observable<boolean>;
  public features$: Observable<FeatureCollection<Geometry, F>>;
  public featuresBoundingBox$: Observable<mapboxgl.LngLatBoundsLike | null>;
  public featuresWithEdits$: Observable<FeatureCollection<Geometry, F>>;
  public geometryTypes$: Observable<Geometry['type'][]>;

  abstract createGeometry(geometry: Geometry): void;
  abstract updateGeometries(geometries: Record<number, Geometry>): void;
  abstract dataToFeature(data: T): GeoJSON.Feature<GeoJSON.Geometry, F>;

  constructor(protected data$: Observable<T[]>) {
    this.selected$ = combineLatest([data$, this.selectedIds$]).pipe(
      map(([surveys, ids]) => surveys.filter(s => ids.includes(s.id)))
    );
    this.selectedCount$ = this.selected$.pipe(map(selected => selected.length));
    this.empty$ = this.data$.pipe(map(data => data.length === 0));
    this.features$ = this.data$.pipe(
      map(data => ({
        type: 'FeatureCollection',
        features: data.filter(d => d.geometry && this.isValidGeometry(d.geometry)).map(d => this.dataToFeature(d))
      })));
    this.featuresBoundingBox$ = this.features$.pipe(map(features => features.features.length === 0 ? null : bbox(features) as mapboxgl.LngLatBoundsLike));

    this.featuresWithEdits$ = combineLatest([this.features$, this.geometryEdits$]).pipe(
      map(([features, edits]) => {
        const featuresWithEdits: FeatureCollection<Geometry, F> = clone(features as AllGeoJSON);
        for (const [id, [, geometry]] of Object.entries(edits)) {
          const idx = featuresWithEdits.features.findIndex(f => f.id === +id);
          if (idx === -1) {
            continue;
          }
          featuresWithEdits.features[idx].geometry = geometry;
        }
        return featuresWithEdits;
      })
    );

    this.geometryTypes$ = this.data$.pipe(
      map(data => {
        const types: Geometry['type'][] = [];
        for (const record of data ?? []) {
          if (record.geometry?.type && !types.includes(record.geometry.type)) {
            types.push(record.geometry.type);
          }
        }
        return types;
      })
    );
  }

  private isLatitude(lat: number): boolean {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }
  private isLongitude(lng: number): boolean {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  private isValidGeometry(geometry: Geometry): boolean {
    const points = explode(geometry as AllGeoJSON);
    for (const point of points.features) {
      if (!this.isLatitude(point.geometry.coordinates[1]) || !this.isLongitude(point.geometry.coordinates[0])) {
        return false;
      }
    }
    return true;
  }

  setHoveredId(id: number | string | null) {

    if (this._hoveredId.value[0] === id) { return; }

    this._hoveredId.next([id, this._hoveredId.value[0]]);
  }

  getSelectedIds(): number[] {
    return this._selectedIds.value;
  }

  selectByIds(ids: number[], source: 'map' | 'table' | null = null) {
    this._selectedSource.next(source);
    this._selectedIds.next(ids);
  }

  select(id: number, source: 'map' | 'table', isShiftPressed: boolean = false) {
    this._selectedSource.next(source);

    if (!isShiftPressed) {
      return this._selectedIds.next([id]);
    }
    const ids = this._selectedIds.value;
    const selectedIds = ids.includes(id) ? ids.filter(id => id !== id) : [...ids, id];
    this._selectedIds.next([...selectedIds]);
  }

  clearSelected() {
    this._selectedSource.next(null);
    this._selectedIds.next([]);
  }

  startMapEditing() {
    this._mapEditing.next(true);
  }

  async stopMapEditing(save = false) {
    const changes = this._geometryEdits.value;

    if (!save) {
      this._geometryEdits.next({});
      this._mapEditing.next(false);
      return;
    }

    // If we are creating something
    if (changes[0]) {
      console.log('Creating', changes[0][1]);
      this.createGeometry(changes[0][1]);
    } else {

      const updates: Record<number, Geometry> = {};
      for (const [id, [, geometry]] of Object.entries(changes)) {
        updates[+id] = geometry;
      }
      this.updateGeometries(updates);
    }
    this._mapEditing.next(false);
    this._geometryEdits.next({});
  }

  // protected readonly _selected = new BehaviorSubject<T | null>(null);
  // selected$ = this._selected.asObservable();

  // protected readonly _hoveredSurveyId = new BehaviorSubject<[number | string | null, number | string | null]>([null, null]);
  // hoveredSurveyId$ = this._hoveredSurveyId.asObservable();

  // setHoveredSurveyId(id: number | string | null) {

  //   if (this._hoveredSurveyId.value[0] === id) { return; }

  //   this._hoveredSurveyId.next([id, this._hoveredSurveyId.value[0]]);
  // }

}
