import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Survey } from './core/models/survey.model';
import { ProjectsService } from './core/services/projects.service';
import { ProjectService } from './features/project/project.service';
import { ProjectBaselineService } from './features/project/baseline/project-baseline.service';

export const surveyResolver: ResolveFn<Survey> = (route) => {
  console.log('Survey Resolver');

  const projectsService = inject(ProjectsService);
  // const projectService = inject(ProjectService);
  const baselineService = inject(ProjectBaselineService);
  const router = inject(Router);

  const surveyId = parseInt(route.paramMap.get("surveyid") as string);

  return projectsService.$project.pipe(
    mergeMap((project) => {
      const survey = project!.surveys.find((a) => a.id === surveyId);
      if (typeof survey !== 'undefined') {
        // baselineService.setSurvey(survey);
        return of(survey);
      }
      router.navigate(['/']);
      return EMPTY;
    })
  );
}

// const handleError = (errorResponse: HttpErrorResponse) => {
//   inject(Router).navigate(['/error']);
//   return of(null);
// }
