import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { tap, map, switchMap, repeat, takeWhile } from 'rxjs/operators';
import { Organisation, OrganisationSchema } from "../models/organisation.model";
import { BehaviorSubject, combineLatest } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { MultiPolygon, Polygon } from "geojson";


@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {

  public _organisationsSubject = new BehaviorSubject<Organisation[]>([]);
  public organisations$ = this._organisationsSubject.asObservable();

  public _currentOrganisationId = new BehaviorSubject<number | null>(null);
  public currentOrganisationId$ = this._currentOrganisationId.asObservable();

  public currentOrganisation$ = combineLatest([this.organisations$, this.currentOrganisationId$]).pipe(
    map(([organisations, currentOrganisationId]) => organisations.find(a => a.id === currentOrganisationId))
  );


  public adminOrganisations$ = this.organisations$.pipe(
    map(organisations => organisations.filter(a => a.role === 'admin' || a.role === 'owner'))
  );
  public adminOrganisationsCount$ = this.adminOrganisations$.pipe(
    map(adminOrganisations => adminOrganisations.length)
  );

  constructor(private http: HttpClient, private userService: UserService) {
    const id = window.localStorage.getItem('orgId');
    if (id !== null) {
      const parsed = parseInt(id);
      if (!isNaN(parsed)) {
        this._currentOrganisationId.next(parsed);
      }
    }
  }

  query() {
    return this.http.get<Organisation[]>(`${environment.apiUrl}/roles`).pipe(
      map(response => OrganisationSchema.array().parse(response)),
      tap((r: Organisation[]) => {
        r.sort((a, b) => a.name.localeCompare(b.name));
        this._organisationsSubject.next(r);
        if (!this._currentOrganisationId.value) {
          this.setOrganisation(r[0].id);
        }
      })
    );
  }

  setOrganisation(id: number) {
    window.localStorage.setItem('orgId', id.toString());
    this._currentOrganisationId.next(id);
  }

  createProject(name: string, description: string, geometry?: Polygon | MultiPolygon) {
    return this.http.post<{ uuid: string }>(`${environment.apiUrl}/project`, {
      name,
      description,
      organisationId: this._currentOrganisationId.value,
      geometry
    }).pipe(
      switchMap((result) => {
        return this.http.get<{ id: number }>(`${environment.apiUrl}/check?uuid=${result.uuid}`, { observe: 'response' }).pipe(
          repeat({ delay: 5000 }),
          takeWhile(response => response.status === 204, true),
          map(response => response.body?.id),
        );
      })
    );
  }

}
