import { Injectable, OnDestroy } from "@angular/core";
import { ProjectService, SurveyFeatureProperties } from "../project.service";
import { BehaviorSubject, Observable, Subject, combineLatest, fromEvent, map, of, switchMap, take, takeUntil, withLatestFrom } from "rxjs";
import { Survey } from "src/app/core/models/survey.model";
import { Feature, FeatureCollection, Geometry, MultiPolygon, Polygon } from "geojson";
import { AllGeoJSON, area, bbox, clone } from "@turf/turf";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ProjectBaseService } from "../project-base.service";

export const ukhabL2Codes = ["g", "f", "c", "h", "r", "s", "t", "u", "w"] as const;
export type UKHabL2Code = typeof ukhabL2Codes[number];
export type HabitatFilter = Record<UKHabL2Code, boolean | null>;
export type GeometryFilter = Record<Geometry['type'], boolean | null>;
type OverlapsFeatureCollection = FeatureCollection<Polygon | MultiPolygon, { aId: number | string; bId: number | string; aName: string; bName: string; }>;

const emptyHabitatFilter: HabitatFilter = {
  g: false,
  f: false,
  c: false,
  h: false,
  r: false,
  s: false,
  t: false,
  u: false,
  w: false
};

const emptyGeometryFilter: GeometryFilter = {
  Point: false,
  MultiPoint: false,
  LineString: false,
  MultiLineString: false,
  Polygon: false,
  MultiPolygon: false,
  GeometryCollection: false
};

@Injectable()
export class ProjectBaselineService extends ProjectBaseService<Survey, SurveyFeatureProperties> implements OnDestroy {

  private worker: Worker;
  destroy$ = new Subject<void>();
  constructor(private projectService: ProjectService, private httpClient: HttpClient) {
    super(projectService.surveys$);
    this.worker = new Worker(new URL('./project.worker', import.meta.url));

    fromEvent<any>(this.worker, 'message').pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ data }) => {
      this._overlaps.next(JSON.parse(data));
    });

    combineLatest([
      this.featuresWithEdits$,
      this.showOverlaps$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([f, show]) => {
      if (!show) {
        this._overlaps.next({
          type: 'FeatureCollection',
          features: []
        } as OverlapsFeatureCollection);
        return;
      }
      this.worker.postMessage(JSON.stringify(f));
    });
  }

  ngOnDestroy() {
    this.worker.terminate();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private readonly _habitatFilter = new BehaviorSubject<HabitatFilter>({
    ...emptyHabitatFilter
  });

  private readonly _geometryFilter = new BehaviorSubject<GeometryFilter>({
    ...emptyGeometryFilter
  });

  private readonly _overlaps = new BehaviorSubject<OverlapsFeatureCollection>({
    type: 'FeatureCollection',
    features: []
  });

  overlaps$ = this._overlaps.asObservable();

  private readonly _showOverlaps = new BehaviorSubject<boolean>(false);
  showOverlaps$ = this._showOverlaps.asObservable();

  habitatFilter$ = this._habitatFilter.asObservable();
  geometryFilter$ = this._geometryFilter.asObservable();

  surveysFiltered$ = combineLatest([this.data$, this.habitatFilter$, this.geometryFilter$]).pipe(map(([surveys, habitatFilter, geometryFilter]) => {
    const habitats = Object.keys(habitatFilter).filter(key => habitatFilter[key as UKHabL2Code] === true);
    const geometry = Object.keys(geometryFilter).filter(key => geometryFilter[key as Geometry['type']] === true);

    if (!habitats.length && !geometry.length) return surveys;

    return surveys.filter(s => {
      const code = s.ukHabPCode?.[0] as UKHabL2Code;
      const type = s.geometry?.type as Geometry['type'];
      return (
        (!habitats.length || habitats.includes(code)) &&
        (!geometry.length || geometry.includes(type))
      );
    });
  }));

  surveysFilteredIds$ = this.surveysFiltered$.pipe(map(surveys => surveys.map(survey => survey.id)));

  mapFilter$ = combineLatest([this.habitatFilter$, this.geometryFilter$]).pipe(
    map(([habitatFilter, geometryFilter]) => {
      const expression: mapboxgl.Expression = ['all'];
      const habitats = Object.keys(habitatFilter).filter(key => habitatFilter[key as UKHabL2Code] === true);
      const geometry = Object.keys(geometryFilter).filter(key => geometryFilter[key as Geometry['type']] === true);

      if (!habitats.length && !geometry.length) {
        return expression;
      }

      if (geometry.length > 0) {
        expression.push(['in', ['geometry-type'], ['literal', geometry]]);
      }

      if (habitats.length > 0) {
        expression.push(['in', ['get', 'l2'], ['literal', habitats]])
      }
      return expression;
    })
  );

  l2Codes$ = this.projectService.project$.pipe(
    map(project => {
      const codes: Set<UKHabL2Code> = new Set<UKHabL2Code>();
      for (const survey of project?.surveys ?? []) {
        const code = survey.ukHabPCode?.[0] as UKHabL2Code;
        if (code) {
          codes.add(code);
        }
      }
      return Array.from(codes);
    })
  );

  imagesSize$ = this.surveysFiltered$.pipe(
    map((surveys) =>
      surveys.reduce(
        (acc, survey) =>
          acc + survey.images.reduce((acc2, image) => acc2 + image.size, 0),
        0
      )
    )
  );

  totalArea$ = this.projectService.surveys$.pipe(
    map((surveys) =>
      surveys.reduce((acc, survey) => acc + (survey.area ?? 0), 0)
    )
  );

  totalLength$ = this.projectService.surveys$.pipe(
    map((surveys) =>
      surveys.reduce((acc, survey) => acc + (survey.length ?? 0), 0)
    )
  );

  // overlaps$: Observable<OverlapsFeatureCollection> = combineLatest([
  //   this.featuresWithEdits$,
  //   this.showOverlaps$
  // ]).pipe(
  //   switchMap(([f, show]) => {
  //     if (!show || typeof Worker === 'undefined') {
  //       return of({
  //         type: 'FeatureCollection',
  //         features: []
  //       } as OverlapsFeatureCollection)
  //     }
  //     worker.postMessage(JSON.stringify(f));
  //     return fromEvent<any>(worker, 'message').pipe(
  //       map(({ data }) => {
  //         return JSON.parse(data);
  //       })
  //     );
  //   })
  // );

  boundary$ = this.projectService.boundary$;
  boundaryBoundingBox$ = this.projectService.boundaryBoundingBox$;

  dataToFeature(survey: Survey): Feature<Geometry, SurveyFeatureProperties> {
    return {
      type: 'Feature',
      geometry: survey.geometry,
      id: survey.id,
      properties: {
        id: survey.id ?? '',
        area: survey.area ?? 0,
        createdAt: survey.createdAt ?? '',
        updatedAt: survey.updatedAt ?? '',
        length: survey.length ?? '',
        ukHabPCode: survey.ukHabPCode ?? '',
        ukHabPName: survey.ukHabPName ?? '',
        ukHabCCode: survey.ukHabCCode ?? '',
        conNotes: survey.conNotes ?? '',
        conCrit: survey.conCrit ?? '',
        conScore: survey.conScore ?? '',
        conResult: survey.conResult ?? '',
        metBUnits: survey.metBUnits ?? '',
        metBrdHab: survey.metBrdHab ?? '',
        metHUnits: survey.metHUnits ?? '',
        metHab: survey.metHab ?? '',
        metHabType: survey.metHabType ?? '',
        metMsg: survey.metMsg ?? '',
        spsTotal: survey.spsTotal ?? '',
        spsPerQ: survey.spsPerQ ?? '',
        nativeWSps: survey.nativeWSps ?? '',
        qRecorded: survey.qRecorded ?? '',
        ukHabS1Cod: survey.ukHabS1Cod ?? '',
        ukHabS1Nam: survey.ukHabS1Nam ?? '',
        ukHabS2Cod: survey.ukHabS2Cod ?? '',
        ukHabS2Nam: survey.ukHabS2Nam ?? '',
        ukHabS3Cod: survey.ukHabS3Cod ?? '',
        ukHabS3Nam: survey.ukHabS3Nam ?? '',
        ukHabS4Cod: survey.ukHabS4Cod ?? '',
        ukHabS4Nam: survey.ukHabS4Nam ?? '',
        ukHabS5Cod: survey.ukHabS5Cod ?? '',
        ukHabS5Nam: survey.ukHabS5Nam ?? '',
        ukHabS6Cod: survey.ukHabS6Cod ?? '',
        ukHabS6Nam: survey.ukHabS6Nam ?? '',
        l2: survey.ukHabPCode?.slice(0, 1),
        featName: survey.featName ?? ''
      }
    }
  }

  setHabitatFilter(filter: Partial<HabitatFilter>) {
    this._habitatFilter.next({
      ...this._habitatFilter.value,
      ...filter
    });
  }

  setGeometryFilter(filter: Partial<GeometryFilter>) {
    this._geometryFilter.next({
      ...this._geometryFilter.value,
      ...filter
    });
  }

  updateSurveyGeometry(id: number, geometry: Geometry) {
    const project = this.projectService.getProject();
    if (!project) {
      return;
    }
    const idx = project.surveys.findIndex(s => s.id === id);
    if (idx === -1) {
      return;
    }

    const _changes = this._geometryEdits.value;
    if (!_changes[id]) {
      _changes[id] = [project.surveys[idx].geometry, geometry];
    } else {
      _changes[id][1] = geometry;
    }
    this._geometryEdits.next(_changes);
  }

  updateGeometries(updates: Record<number, Geometry>) {
    const project = this.projectService.getProject();

    for (const [id, geometry] of Object.entries(updates)) {
      const idx = project!.surveys.findIndex(s => s.id === +id);
      if (idx === -1) {
        return;
      }
      project!.surveys[idx].geometry = geometry;
      project!.surveys[idx].type = geometry.type;
      project!.surveys[idx].area = area({
        type: 'Feature',
        geometry,
        properties: {}
      }) / 10000;
    }
    this.projectService.setProject(project!)

    this.httpClient.post(`${environment.apiUrl}/geometry`, updates).subscribe(() => {
      console.log('Done');
    });
  }

  createGeometry(geometry: Geometry) {
    throw new Error('Cannot create basleine geometry yet');
  }

  showOverlaps(show: boolean) {
    this._showOverlaps.next(show);
  }

  updateFeatureName(id: number, featName: string) {
    return this.httpClient.post(`${environment.apiUrl}/featurename`, { id, featName }).subscribe(() => {
      const project = this.projectService.getProject();
      if (!project) {
        return;
      }
      const idx = project.surveys.findIndex(s => s.id === id);
      if (idx === -1) {
        return;
      }
      project.surveys[idx].featName = featName;
      this.projectService.setProject(project);
    });
  }

}
