import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Project, ProjectSchema, ProjectSummary, ProjectSummarySchema } from "../models/project.model";
import { Survey } from "../models/survey.model";
import { ImportPreSurveyQuery, ImportPreSurveyQueryInput } from "../queries/pre-survey.queries"

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  private currentProject = new BehaviorSubject<Project | null>(null);
  public $project = this.currentProject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(private http: HttpClient) { }

  query() {
    return this.http.get<ProjectSummary[]>(`${environment.apiUrl}/projects`).pipe(
      map(response => ProjectSummarySchema.array().parse(response)
      )
    );
  }

  get(id: number, isUpdate: boolean = false): Observable<Project> {
    if (this.currentProject.value?.id === id && !isUpdate) {
      return this.$project as Observable<Project>;
    }

    return this.http.get<Project>(`${environment.apiUrl}/project?id=${id}`).pipe(
      map((result) => {
        const project = ProjectSchema.safeParse(result);
        if (project.success) {
          this.currentProject.next(project.data);
        } else {
          console.warn(project.error);
        }
        return (project as any).data as Project;
      })
    );
  }

  getSurvey(id: number, surveyId: number): Observable<Survey | undefined> {
    return this.get(id, true).pipe(
      map((project) => project.surveys.find((a) => a.id === surveyId))
    );
  }

  importPreSurvey(surveyId: number, file: File, pathMap: { [key: string]: string }) {
    let form = new FormData();
    form.append('query', ImportPreSurveyQuery);
    form.append('file', file);
    // form.append('variables', JSON.stringify(options.variables));
    const input: ImportPreSurveyQueryInput = {
      map: pathMap,
      surveyId,
      format: 'Shapefile',
      epsgCode: null
    };
    form.append('variables', JSON.stringify({ input }));
    return this.http.post('https://api.coreo.io/graphql', form);
  }
}
