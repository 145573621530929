export const ImportPreSurveyQuery = `
  mutation($input: RecordsImportInput!){
    result: importRecords(input: $input)
  }
`;

export interface ImportPreSurveyQueryInput {
  surveyId: number;
  map: { [key: string]: string };
  format: "Shapefile";
  epsgCode: null;
}
