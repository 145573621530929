import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtService } from "../services/jwt.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly JwtService: JwtService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.JwtService.getToken();
    const request = req.clone({
      setHeaders: {
        ...(token && req.url.startsWith('https://api.coreo.io') ? { Authorization: `JWT ${token}` } : {})
      },
      setParams: {
        ...(token && !req.url.endsWith('graphql') ? { token } : {})
      }
    });
    return next.handle(request);
  }
}
