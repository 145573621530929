import { z } from 'zod';
import { daforScores, dominScores } from '../services/utils.service';

const Attachment = z.object({
  id: z.number(),
  url: z.string(),
  size: z.number()
});

const SpeciesSchema = z
  .object({
    id: z.number(),
    spsName: z.string(),
    qNumber: z.number().optional(),
    dominScore: z.enum(dominScores).optional(),
    daforScore: z.enum(daforScores).optional(),
    spsNote: z.string().optional(),
    images: Attachment.array().optional()
  })
  .strict();

const SurveyLog = z
  .object({
    operation: z.string(),
    userId: z.number(),
    createdAt: z.string().datetime({ offset: true }),
    diff: z.any().optional(),
    recordId: z.number().optional()
  })
  .strict();

export const SurveySchema = z
  .object({
    id: z.number(),
    name: z.string().optional(),
    featName: z.string().optional(),
    ukHabPName: z.string().optional(),
    ukHabPCode: z.string().optional(),
    ukHabCCode: z.string().optional(),
    ukHabS1Cod: z.string().optional(),
    ukHabS1Nam: z.string().optional(),
    ukHabS2Cod: z.string().optional(),
    ukHabS2Nam: z.string().optional(),
    ukHabS3Cod: z.string().optional(),
    ukHabS3Nam: z.string().optional(),
    ukHabS4Cod: z.string().optional(),
    ukHabS4Nam: z.string().optional(),
    ukHabS5Cod: z.string().optional(),
    ukHabS5Nam: z.string().optional(),
    ukHabS6Cod: z.string().optional(),
    ukHabS6Nam: z.string().optional(),
    type: z.string(),
    distinctiv: z.number().optional(),
    conScore: z.any(),
    conResult: z.string().optional(),
    conNotes: z.string().optional(),
    geometry: z.any(),
    area: z.number().optional(),
    length: z.number().optional(),
    latitude: z.number().optional(),
    longitude: z.number().optional(),
    habNotes: z.string().optional(),
    images: Attachment.array(),
    metHabType: z.string().optional(),
    metBrdHab: z.string().optional(),
    metHab: z.string().optional(),
    metHabIrr: z.boolean().nullable().optional(),
    metMsg: z.string().optional(),
    userId: z.number(),
    qRecorded: z.number(),
    spsTotal: z.number().optional(),
    spsPerQ: z.number().optional(),
    nativeWSps: z.number().optional(),
    conCrit: z.string().optional(),
    conCrit1: z.any().optional(),
    conCrit2: z.any().optional(),
    conCrit3: z.any().optional(),
    conCrit4: z.any().optional(),
    conCrit5: z.any().optional(),
    conCrit6: z.any().optional(),
    conCrit7: z.any().optional(),
    conCrit8: z.any().optional(),
    conCrit9: z.any().optional(),
    conCrit10: z.any().optional(),
    conCrit11: z.any().optional(),
    conCrit12: z.any().optional(),
    conCrit13: z.any().optional(),
    conCritX: z.any().optional(),
    species: SpeciesSchema.array(),
    hedge: z.boolean().optional(),
    metBUnits: z.number().optional(),
    metHUnits: z.number().optional(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
    state: z.number(),
    logs: SurveyLog.array()
  })
  .strict();

export type Survey = z.infer<typeof SurveySchema>;
export type SurveyLog = z.infer<typeof SurveyLog>;
export type Attachment = z.infer<typeof Attachment>;
export type Species = z.infer<typeof SpeciesSchema>;
