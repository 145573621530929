import { Injectable } from "@angular/core";
import { DownZip, DownZipFile } from "downzip";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private downZip: DownZip;

  async init(): Promise<DownZip> {
    if (this.downZip) {
      return this.downZip;
    }
    const DownZip: any = await import('downzip');
    const downZip: DownZip = new DownZip.default();
    await downZip.register();
    return downZip;
  }

  async createDownload(downloadId: string, zipFileName: string, files: DownZipFile[]): Promise<string> {
    const downZip = await this.init();
    return downZip.downzip(downloadId, zipFileName, files);
  }

  blobToDownZipFile(blob: Blob, filename: string): DownZipFile {
    return {
      name: filename,
      size: blob.size,
      downloadUrl: URL.createObjectURL(blob)
    }
  }

  base64toBlob = (base64: string) => fetch(base64).then(res => res.blob());
}
