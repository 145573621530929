import { NgModule, inject } from '@angular/core';
import {
  CanActivateFn,
  PreloadAllModules,
  Router,
  RouterModule,
  Routes
} from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from './core/services/user.service';
import { projectResolver } from './project.resolver';
import { surveyResolver } from './survey.resolver';
import { ProjectService } from './features/project/project.service';
import { MAPS_SERVICE_PERSIST, MapsService } from './core/services/maps.service';
import { ProjectBaselineService } from './features/project/baseline/project-baseline.service';
import { ProjectPreSurveyService } from './features/project/pre-survey/project-pre-survey.service';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.isAuthenticated$.pipe(
    switchMap((isAuth) => {
      if (isAuth) {
        return of(true);
      }
      router.navigate(['/login']);
      return of(false);
    })
  );
};

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./features/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
    canActivate: [authGuard],
    // providers: [ProjectService],
  },
  {
    path: 'projects/:id',
    resolve: {
      project: projectResolver
    },
    providers: [ProjectBaselineService, ProjectPreSurveyService],
    // providers: [ProjectService],
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'baseline',
        pathMatch: 'full'
      },
      {
        path: 'baseline',
        providers: [MapsService],
        loadComponent: () =>
          import('./features/project/baseline/project-baseline.component').then(
            (m) => m.ProjectBaselineComponent
          ),
        children: [
          {
            path: 'activity',
            loadComponent: () =>
              import('./features/project/project-activity.component').then(
                (m) => m.ProjectActivityComponent
              )
          },
          {
            path: 'filter',
            loadComponent: () =>
              import('./features/project/project-filter.component').then(
                (m) => m.ProjectFilterComponent
              )
          },
          {
            path: 'recorders',
            loadComponent: () =>
              import('./features/project/project-recorders.component').then(
                (m) => m.ProjectRecordersComponent
              )
          },
          {
            path: 'geometry',
            loadComponent: () =>
              import('./features/project/project-geometry.component').then(
                (m) => m.ProjectGeometryComponent
              )
          }
        ]
      },
      {
        path: 'pre-survey',
        providers: [
          MapsService,
          {
            provide: MAPS_SERVICE_PERSIST,
            useValue: true
          }],
        loadComponent: () =>
          import('./features/project/pre-survey/project-pre-survey.component').then(
            (m) => m.ProjectPreSurveyComponent
          ),
      },
      {
        path: 'post-development',
        providers: [
          MapsService,
          {
            provide: MAPS_SERVICE_PERSIST,
            useValue: true
          }],
        loadComponent: () =>
          import('./features/project/post-development/project-post-development.component').then(
            (m) => m.ProjectPostDevelopmentComponent
          ),
      },
      {
        path: 'baseline/:surveyid',
        providers: [
          MapsService,
          {
            provide: MAPS_SERVICE_PERSIST,
            useValue: false
          }],
        loadComponent: () =>
          import('./features/survey/survey.component').then(
            (m) => m.SurveyComponent
          ),
        resolve: {
          survey: surveyResolver
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./features/survey/survey-results.component').then(
                (m) => m.SurveyResultsComponent
              )
          },
          {
            path: 'species',
            loadComponent: () =>
              import('./features/survey/survey-species.component').then(
                (m) => m.SurveySpeciesComponent
              )
          },
          {
            path: 'condition',
            loadComponent: () =>
              import('./features/survey/survey-condition-criteria.component').then(
                (m) => m.SurveyConditionCriteriaComponent
              )
          },
          {
            path: 'photos',
            loadComponent: () =>
              import('./features/survey/survey-photos.component').then(
                (m) => m.SurveyPhotosComponent
              )
          }
        ]
      }
    ]
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./core/auth/login.component').then((m) => m.LoginComponent)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
