import { DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import jwt_decode from "jwt-decode";

import { provideAngularSvgIcon } from 'angular-svg-icon';
import { EMPTY } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { HeaderComponent } from './core/layout/header.component';
import { User } from './core/models/user.model';
import { JwtService } from './core/services/jwt.service';
import { OrganisationsService } from './core/services/organisations.service';
import { UserService } from './core/services/user.service';
import { DemoInterceptor } from './core/interceptors/demo.interceptor';

export function initAuth(jwtService: JwtService, userService: UserService, organisationService: OrganisationsService) {
  return () => {
    const token = jwtService.getToken();
    if (token) {
      const {
        userId: id,
        displayName,
        email,
        imageUrl,
        username
      } = jwt_decode<{
        displayName: string;
        email: string;
        userId: number;
        username: string;
        imageUrl: string;
      }>(token);
      const user: User = {
        id,
        displayName,
        username,
        email,
        imageUrl
      };
      userService.setAuth(token, user);
      return organisationService.query();
    }
    return EMPTY;
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HeaderComponent,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogModule
  ],
  providers: [{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  }, {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [JwtService, UserService, OrganisationsService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DemoInterceptor, multi: true },
    provideAngularSvgIcon()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
