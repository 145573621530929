<nav class="w-full h-16 bg-white flex justify-between items-center px-5 leading-10 text-black border-b border-gray-200">
  <div class="flex items-center pr-5 border-r border-gray-200">
    <a routerLink="/"><img src="/assets/coreo.svg" class="h-10" /></a>
  </div>
  <!-- <div class="flex items-center text-xl leading-5 font-semibold mr-auto max-sm:hidden">
    <img src="/assets/logo.svg" class="h-10 pr-2 pl-5" alt="UKHab Portal Logo" />
    UKHab Data Portal
  </div> -->
  <div class="flex items-center flex-grow justify-center">
    <button *ngIf="project$ | async as project" [cdkMenuTriggerFor]="projects"
      class="bg-white font-semibold px-5 flex items-center text-[#878F97] hover:text-primary">
      <span class="text-black">{{project.name}}</span>
      <span *ngIf="project.ukHabVersion === 'ukhab3.1'"
        class="mx-2 rounded-lg mr-auto py-1 px-2 bg-[#FEEA82] text-black text-xs font-semibold">
        Metric 3.1
      </span>
      <svg-icon src="/assets/icons/arrow.svg" class="w-3 h-3 ml-5" />
    </button>
  
    <ng-template #projects>
      <ul class="bg-white mt-2 rounded" cdkMenu>
        <li *ngFor="let project of projects$ | async">
          <a [routerLink]="['/', 'projects', project.id]" class="px-2 py-1 block" cdkMenuItem>{{project.name}}</a>
        </li>
      </ul>
    </ng-template>
  </div>

  <!-- Organisation Menu -->
  <button [cdkMenuTriggerFor]="orgs" *ngIf="loggedIn$ | async"
    class="button-white px-2 text-sm h-8 mx-2 flex items-center" [ngClass]="{'disabled': !(switchOrgs$ | async)}">
    <span class="text-gray-400 mr-2">Organisation:</span>
    <span>{{(org$ | async)?.name}}</span>
    <svg-icon src="/assets/icons/arrow.svg" class="text-xs text-primary w-4 h-4 ml-1" *ngIf="switchOrgs$ | async" />
  </button>

  <button [cdkMenuTriggerFor]="menu" class="h-full flex items-center" *ngIf="loggedIn$ | async">
    <img [src]="image$ | async" class="h-8 w-8 rounded-full" />
  </button>

  <ng-template #menu>
    <ul class="bg-white mt-2 rounded-lg drop-shadow-lg p-2.5 w-32" cdkMenu>
      <li>
        <button (click)="logout()" class="px-2 py-1 block" cdkMenuItem>Logout</button>
      </li>
    </ul>
  </ng-template>

  <ng-template #orgs>
    <ul class="bg-white rounded mt-2 border border-gray-500 w-64 px-4 overflow-y-auto max-h-[90vh]" cdkMenu>
      <li *ngFor="let org of orgs$ | async" (click)="switchOrganisation(org)"
        class="flex items-center bg-white py-2 hover:text-primary cursor-pointer" cdkMenuItem>
        {{org.name}}
      </li>
    </ul>
  </ng-template>

</nav>
